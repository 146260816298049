import { Component, Mixins } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import { Module, ModuleCollection } from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Module, ModuleCollection>
>;

@Component
export default class ModulesMixin extends Mixins(TypedModelMixin) {
  obCollection = new ModuleCollection();
  obModelClass = Module;
  sRoutePath = "/modules";

  created() {
    this.onCreated();
  }
}
